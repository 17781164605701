import "./Btn.scss";
import { ReactNode } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { Status } from "../../services/models/Status";

type PropsBtn = {
  href?: string;
  icon?: string;
  status?: Status;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
};

export default function Btn({
  href,
  icon,
  children,
  status,
  onClick,
  disabled = false,
  fullWidth = false,
}: PropsBtn) {
  const Content = (
    <>
      <span>{children}</span>
      {icon && <Icon icon={icon} />}
    </>
  );

  if (onClick) {
    return (
      <button
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          onClick(e)
        }
        className="Btn-btn"
        data-status={status}
        disabled={disabled}
        style={{ width: fullWidth ? "100%" : undefined }}
      >
        {Content}
      </button>
    );
  }

  if (!href) {
    return <></>;
  }

  return (
    <Link to={href} className="Btn-btn" data-status={status}>
      {Content}
    </Link>
  );
}
