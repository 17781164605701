import { useParams } from "react-router";
import PageLayout from "../components/Page/PageLayout";
import PageBase from "../components/Page/PageBase";
import { KEEPIX_API_URL } from "../constants";

export default function PluginPage() {
  const params = useParams();

  return (
    <PageLayout>
      {/* <PageBase title="Plugin"> */}
        <iframe
          src={`${KEEPIX_API_URL}/plugins/${params.id}/view/`}
          width="100%"
          style={{
            height: "100vh",
            border: "0px",
            marginLeft: window.innerWidth < 1025 ? undefined : "-79px",
            width: window.innerWidth < 1025 ? undefined : "calc(100% + 158px)",
            marginTop: window.innerWidth < 1025 ? undefined : "-78px"
          }}
        ></iframe>
      {/* </PageBase> */}
    </PageLayout>
  );
}
