import Popin from "../../components/Popin/Popin";
import "./APIProvider.scss";
import {
  ReactNode,
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";

export type APIState = "UP" | "WAITING" | "UNREACHABLE";

type APIContextValue = {
  apiState: APIState | null;
  setAPIState: Dispatch<SetStateAction<APIState | null>>;
};

export const APIContext = createContext<APIContextValue | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const useAPIContext = () => {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error("useAPIContext must be inside a APIProvider");
  }
  return context;
};

export default function APIProvider({ children }: Props) {
  const [apiState, setAPIState] = useState<APIContextValue["apiState"]>(null);

  return (
    <APIContext.Provider value={{ apiState, setAPIState }}>
      {children}
      {/* {apiState !== "UP" && (
        <Popin title="Connection unstable">
          {apiState === "WAITING" && (
            <div className="APIProvider-main">
              <div>Trying to reconnect to your Keepix please wait.</div>
            </div>
          )}
          {apiState === "UNREACHABLE" && (
            <div className="APIProvider-main">
              <div className="APIProvider-text">
                Keepix network unreachable, please reset the network using the
                WAP installation or ethernet after rebooting your device.
                <br />
                Go to <a href="https://keepix.io/setup">keepix.io/setup</a> to
                relink the network.
              </div>
            </div>
          )}
        </Popin>
      )} */}
    </APIContext.Provider>
  );
}
