import { Icon } from "@iconify/react";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import BannerAlert from "../components/BannerAlert/BannerAlert";
import Btn from "../components/Btn/Btn";
import Loader from "../components/Loader/Loader";
import Popin from "../components/Popin/Popin";
import { PluginActionType } from "../model/Plugin";
import {
  getPluginCollection,
  postPluginInstall,
  getTaskProgress,
  postPluginUninstall,
  postPluginUpdate,
} from "../queries/api";
import PageLayout from "../components/Page/PageLayout";
import PageBase from "../components/Page/PageBase";
import "../scss/pages/PluginStorePage.scss";
import { Checkbox, SelectWallet } from "../components/Form/Form";

const InstallPluginButton = ({ plugin }: any) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [error, setError] = useState("");
  const [installed, setInstalled] = useState(false);

  // prepare the formGroup with all defaultValue
  const [formGroup, updateFormGroup] = useState<any>(
    plugin.requires?.installForm?.inputs?.reduce((acc: any, input: any) => {
      if (input.defaultValue !== undefined) {
        acc[input.key] = input.defaultValue;
      }
      return acc;
    }, {}) ?? {}
  );
  const [validForm, setValidForm] = useState(false);

  const qTaskProgress = useQuery({
    queryKey: ["taskProgress", plugin.id, taskId],
    queryFn: () => getTaskProgress(plugin.id, taskId),
    enabled: taskId !== "",
    refetchInterval: 1000,
    refetchOnWindowFocus: true, // force refresh data
  });

  const handleInstall = async (data: any) => {
    setLoading(true);
    const response = await postPluginInstall(
      plugin.id,
      plugin.latestVersion,
      data
    );

    if (response.taskId !== undefined) {
      setTaskId(response.taskId);
    } else if (response.error !== undefined) {
      setError(response.error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (qTaskProgress?.data?.status === "ERROR") {
      setError(qTaskProgress.data.description);
      setLoading(false);
    }
    if (qTaskProgress?.data?.status === "FINISHED") {
      setInstalled(true);
      setLoading(false);
    }
  }, [qTaskProgress]);

  useEffect(() => {
    let isValid = true;
    if (plugin.requires?.installForm?.inputs !== undefined) {
      for (let input of plugin.requires.installForm.inputs) {
        if (formGroup[input.key] === undefined) {
          isValid = false;
        }
      }
    }
    console.log(formGroup, isValid);
    setValidForm(isValid);
  }, [formGroup]);

  return (
    <>
      <Btn
        onClick={() => {
          setOpen(true);
        }}
        icon="ph:download"
      >
        Install Plugin
      </Btn>
      {open && (
        <>
          <Popin title={`Install ${plugin.title}`} close={() => setOpen(false)}>
            {error !== "" && <BannerAlert status="danger">{error}</BannerAlert>}
            {installed && (
              <>
                <BannerAlert status="success">Plugin Installed</BannerAlert>
                <Btn href={`/plugin/${plugin.id}`} status="success">
                  Go to the Dashboard
                </Btn>
              </>
            )}
            {!installed && !loading && (
              <>
                {plugin?.requires?.installForm?.description}
                {plugin.requires?.installForm?.inputs !== undefined &&
                  plugin.requires.installForm.inputs.map((input: any) => {
                    switch (input.type) {
                      case "wallet":
                        return (
                          <SelectWallet
                            key={input.key}
                            name={input.key}
                            label={input.label}
                            type={input.walletType}
                            walletKey={input.walletKey}
                            filter={input.filter}
                            labelIfEmptyWalletList={
                              input.labelIfEmptyWalletList ?? ""
                            }
                            onChange={(event: any) => {
                              const walletTargetValue =
                                event !== "null" && event.success === true
                                  ? event.wallet[input.walletKey]
                                  : undefined;
                              updateFormGroup({
                                ...formGroup,
                                [input.key]: walletTargetValue,
                              });
                            }}
                          ></SelectWallet>
                        );
                      case "checkbox-2":
                        return (
                          <Checkbox
                            key={input.key}
                            name={input.key}
                            label={input.label}
                            enabled={input.true}
                            disabled={input.false}
                            defaultValue={input.defaultValue}
                            onChange={(event: boolean) => {
                              updateFormGroup({
                                ...formGroup,
                                [input.key]: event,
                              });
                            }}
                          />
                        );
                    }
                    return undefined;
                  })}
              </>
            )}
            {!loading && !installed && (
              <Btn
                onClick={() => handleInstall(formGroup)}
                disabled={validForm === false}
              >
                Install
              </Btn>
            )}
            {loading && (
              <Loader
                label={
                  qTaskProgress.data &&
                  qTaskProgress.data.status === "INSTALLING"
                    ? `${qTaskProgress.data.description}${
                        qTaskProgress.data.percent !== undefined
                          ? " - " + qTaskProgress.data.percent + "%"
                          : ""
                      }`
                    : undefined
                }
              />
            )}
          </Popin>
        </>
      )}
    </>
  );
};

const UninstallPluginButton = ({ plugin, onChange }: any) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [error, setError] = useState("");
  const [uninstalled, setUninstalled] = useState(false);

  const handleUninstall = async (data: any) => {
    setLoading(true);
    const response = await postPluginUninstall(plugin.id);

    if (response.taskId !== undefined) {
      setTaskId(response.taskId);
    } else if (response.error !== undefined) {
      setError(response.error);
      setLoading(false);
    }
  };

  const qTaskProgress = useQuery({
    queryKey: ["taskProgress", plugin.id, taskId],
    queryFn: () => getTaskProgress(plugin.id, taskId),
    enabled: taskId !== "",
    refetchInterval: 1000,
  });

  useEffect(() => {
    if (qTaskProgress?.data?.status === "ERROR") {
      setError(qTaskProgress.data.description);
      setLoading(false);
    }
    if (qTaskProgress?.data?.status === "FINISHED") {
      setUninstalled(true);
      setLoading(false);
      onChange();
    }
  }, [qTaskProgress]);

  return (
    <>
      <Btn
        onClick={() => {
          setOpen(true);
        }}
        icon="ph:upload"
        status="danger"
      >
        Uninstall
      </Btn>
      {open && (
        <>
          <Popin
            title={`Uninstall ${plugin.title}`}
            close={() => setOpen(false)}
          >
            {loading && <Loader />}
            {error !== "" && <BannerAlert status="danger">{error}</BannerAlert>}
            {uninstalled && (
              <>
                <BannerAlert status="success">Plugin Uninstalled</BannerAlert>
              </>
            )}
            {!loading && !uninstalled && (
              <Btn onClick={() => handleUninstall(plugin.id)} status="danger">
                Uninstall
              </Btn>
            )}
          </Popin>
        </>
      )}
    </>
  );
};

const UpdatePluginButton = ({ plugin, onChange }: any) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [error, setError] = useState("");
  const [updated, setUpdated] = useState(false);

  const handleUpdate = async (data: any) => {
    setLoading(true);
    const response = await postPluginUpdate(plugin.id, plugin.latestVersion, {});

    if (response.taskId !== undefined) {
      setTaskId(response.taskId);
    } else if (response.error !== undefined) {
      setError(response.error);
      setLoading(false);
    }
  };

  const qTaskProgress = useQuery({
    queryKey: ["taskProgress", plugin.id, taskId],
    queryFn: () => getTaskProgress(plugin.id, taskId),
    enabled: taskId !== "",
    refetchInterval: 1000,
  });

  useEffect(() => {
    if (qTaskProgress?.data?.status === "ERROR") {
      setError(qTaskProgress.data.description);
      setLoading(false);
    }
    if (qTaskProgress?.data?.status === "FINISHED") {
      setUpdated(true);
      setLoading(false);
      onChange();
    }
  }, [qTaskProgress]);

  return (
    <>
      <Btn
        onClick={() => {
          setOpen(true);
        }}
        icon="ph:download"
        disabled={!plugin.installed || plugin.latestVersion === plugin.version}
      >
        Update to {plugin.latestVersion}
      </Btn>
      {open && (
        <>
          <Popin
            title={`Update ${plugin.title}`}
            close={() => setOpen(false)}
          >
            {loading && <Loader />}
            {error !== "" && <BannerAlert status="danger">{error}</BannerAlert>}
            {updated && (
              <>
                <BannerAlert status="success">Plugin Updated</BannerAlert>
              </>
            )}
            {!loading && !updated && (
              <Btn onClick={() => handleUpdate(plugin.id)} status="success">
                Update to {plugin.latestVersion}
              </Btn>
            )}
          </Popin>
        </>
      )}
    </>
  );
};

export default function PluginStorePage() {
  const qPluginCollection = useQuery({
    queryKey: ["plugins"],
    queryFn: getPluginCollection,
    refetchOnWindowFocus: true, // force refresh data
  });

  const style = { "--color": "64 173 230" } as React.CSSProperties;

  return (
    <PageLayout>
      <PageBase
        title="Plugins Store"
        subTitle="Manage the plugins on your Keepix"
        icon="ph:plus-square"
      >
        {qPluginCollection.data && (
          <>
            {qPluginCollection.data.length !== 0 && (
              <ul className="PluginStorePage-grid">
                {qPluginCollection.data.map((plugin, key) => (
                  <li key={key} className="PluginStorePage-item" style={style}>
                    <div className="PluginStorePage-itemCard card">
                      {!plugin.icon && (
                        <div className="PluginStorePage-itemIcon icon-app">
                          <Icon icon="cryptocurrency:eth" />
                        </div>
                      )}
                      {plugin.icon && !plugin.icon.startsWith("http") && (
                        <div className="PluginStorePage-itemIcon icon-app">
                          <Icon icon={plugin.icon} />
                        </div>
                      )}
                      {plugin.icon && plugin.icon.startsWith("http") && (
                        <div className="PluginStorePage-itemIcon icon-app">
                          <img
                            src={plugin.icon}
                            style={{ width: "50px", height: "50px", borderRadius: '50px' }}
                          />
                        </div>
                      )}
                      <div className="PluginStorePage-itemContent">
                        <h3 className="PluginStorePage-itemTitle">
                          {plugin.title}
                        </h3>
                        <span className="PluginStorePage-itemInstall">
                          {plugin.installed && (
                            <span>Installed v{plugin.version}</span>
                          )}
                          {!plugin.installed && plugin.latestVersion && (
                            <>Not Installed (v{plugin.latestVersion})</>
                          )}
                          {!plugin.installed && !plugin.latestVersion && (
                            <>Not Installed</>
                          )}
                        </span>
                        <hr
                          style={{
                            // you made me fix it, this is your fault
                            border: "none",
                            height: "1px",
                            backgroundColor: "rgb(84, 181, 213)",
                            backgroundImage:
                              "linear-gradient(to right, rgb(0, 0, 68), rgb(44, 88, 98), rgb(0, 0, 68))",
                            margin: "10px 0",
                          }}
                        />
                      </div>
                      <div
                        className="PluginStorePage-itemContent"
                        style={{
                          padding: "0px 50px 0px 50px",
                          textAlign: "justify",
                        }}
                      >
                        <span
                          style={{
                            color: "rgb(149 176 233 / 96%)",
                            fontSize: 12,
                          }}
                        >
                          {plugin.description}
                        </span>
                      </div>
                      <div className="PluginStorePage-itemLinks">
                        <Btn
                          href={
                            "https://www.npmjs.com/package/" +
                            plugin.packageName
                          }
                          icon="logos:npm"
                          status="danger"
                        >
                          Npm Package Link
                        </Btn>
                        <Btn
                          href={plugin.repositoryUrl}
                          icon="entypo-social:github"
                          status="warning"
                        >
                          Github Link
                        </Btn>
                      </div>
                      <div>
                        {!plugin.installed ? (
                          <InstallPluginButton
                            plugin={plugin}
                          ></InstallPluginButton>
                        ) : (
                          <UninstallPluginButton
                            plugin={plugin}
                            onChange={() => {
                              qPluginCollection.refetch();
                            }}
                          ></UninstallPluginButton>
                        )}
                        {plugin.installed && (
                          <UpdatePluginButton
                            plugin={plugin}
                            onChange={() => {
                              qPluginCollection.refetch();
                            }}
                          ></UpdatePluginButton>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </PageBase>
    </PageLayout>
  );
}
