import "./SidebarPlugins.scss";
import Loader from "../Loader/Loader";
import BannerAlert from "../BannerAlert/BannerAlert";
import { Link } from "react-router-dom";
import { Icon } from "@iconify-icon/react";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getPluginCollection } from "../../queries/api";

export default function SidebarPlugins() {
  const params = useParams();

  const qPluginCollection = useQuery({
    queryKey: ["plugins"],
    queryFn: getPluginCollection,
  });

  const installedPlugins = qPluginCollection.data
    ? qPluginCollection.data.filter((plugin) => plugin.installed)
    : [];

  return (
    <div className="SidebarPlugins-main">
      <div className="SidebarPlugins-title">Plugins</div>
      {qPluginCollection.isLoading && <Loader />}
      {qPluginCollection.isError && (
        <BannerAlert status="danger">
          {qPluginCollection.error.message}
        </BannerAlert>
      )}
      {!qPluginCollection.isLoading &&
        !qPluginCollection.isError &&
        installedPlugins.length === 0 && (
          <BannerAlert status="info">No app installed yet.</BannerAlert>
        )}

      {installedPlugins.length !== 0 && (
        <ul className="SidebarPlugins-list">
          {installedPlugins.map((plugin, key) => (
            <li
              className="SidebarPlugins-item"
              key={key}
              data-active={params.id !== undefined && params.id === plugin.id}
            >
              <Link
                to={`/plugin/${plugin.id}`}
                className="SidebarPlugins-itemLink"
              >
                <div className="SidebarPlugins-itemIcon">
                  <Icon icon={plugin.icon} />
                </div>
                <div className="SidebarPlugins-itemContent">
                  <span className="SidebarPlugins-itemTitle">{plugin.title}</span>
                  <span className="SidebarPlugins-itemSubtitle">
                    {plugin.subTitle}
                  </span>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
