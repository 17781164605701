import "./Form.scss";
import { Icon } from "@iconify-icon/react";
import { CheckboxProps, FormProps, InputProps } from "../../model/Form";
import { useEffect, useState } from "react";
import Btn from "../Btn/Btn";
import Popin from "../Popin/Popin";
import Loader from "../Loader/Loader";
import BannerAlert from "../BannerAlert/BannerAlert";
import { useQuery } from "@tanstack/react-query";
import { getKeepixWalletSecret, getKeepixWallets } from "../../queries/api";
import { evalIsValid } from "../../lib/utils";

/** Form container */

export const Form = ({ children }: FormProps) => {
  return <form className="Form-form">{children}</form>;
};

/** Input wrapper with label */

export const Input = ({
  label,
  small,
  name,
  icon,
  required,
  children,
  disabled,
  displayLabel = true,
  overrideStyle = {}
}: InputProps) => {
  return (
    <div>
      {displayLabel && (<label className="Form-label" htmlFor={name}>
        {label}: {required && <abbr title="Required">*</abbr>}
        {small && <small>{small}</small>}
      </label>)}
      <div className="Form-input" style={overrideStyle} data-disabled={disabled}>
        {icon && <Icon icon={icon} />}
        {children}
        <span></span>
      </div>
    </div>
  );
};

/** Radio / Checkbox */

export const Checkbox = ({
  label,
  name,
  type = "checkbox",
  enabled,
  disabled,
  defaultValue = false,
  style = {},
  onChange = () => {},
  disableLabel = false
}: CheckboxProps) => {
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    onChange(selected);
  }, [selected]);
  return (
    <div className="Form-checkbox">
      {!disableLabel && (<div className="Form-label">{label}:</div>)}
      <input
        onClick={() => {
          setSelected(!selected);
        }}
        className={selected ? "active" : ""}
        id={name}
        name={name}
        type={type}
      />
      <label style={style} className="Form-checkboxCase" htmlFor={name}>
        {disabled && (
          <span className="Form-checkboxCaseDisabled">{disabled}</span>
        )}
        {enabled && <span className="Form-checkboxCaseEnabled">{enabled}</span>}
      </label>
    </div>
  );
};

export const SelectWallet = ({
  type,
  labelIfEmptyWalletList,
  filter = "true",
  onChange = () => {},
}: any) => {
  const [walletWithSecret, setSelectedWalletWithSecret] = useState<any>("null");
  const [wallet, setSelectedWallet] = useState<any>("null");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const checkFilterEval = (wallet: any) => {
    return evalIsValid({ wallet: wallet }, filter);
  };

  useEffect(() => {
    onChange(walletWithSecret);
  }, [walletWithSecret]);

  const handleSelectWalletWithSecret = async (wallet: any) => {
    setLoading(true);
    const walletWithSecret = await getKeepixWalletSecret(wallet.address);
    setSelectedWalletWithSecret(walletWithSecret);
    setLoading(false);
    setOpen(false);
  };

  const qKeepixWallets = useQuery({
    queryKey: ["keepingWallets"],
    queryFn: async () => {
      const wallets = await getKeepixWallets();
      return wallets.data.filter((x: any) => x.type === type && checkFilterEval(x));
    },
    enabled: open,
  });

  return (
    <>
      {walletWithSecret === "null" && (
        <Btn
          icon="ph:wallet-fill"
          onClick={() => {
            setOpen(true);
          }}
        >
          Select One "{type}" Wallet
        </Btn>
      )}
      {walletWithSecret !== "null" && (
        <>
          <div>Wallet {wallet.address} Selected.</div>
        </>
      )}
      {open && (
        <Popin
          title={`Select One "${type}" Wallet`}
          close={() => {
            setOpen(false);
          }}
        >
          {(loading || !qKeepixWallets.data) && <Loader />}
          {error !== "" && <BannerAlert status="danger">{error}</BannerAlert>}
          {qKeepixWallets.data && qKeepixWallets.data.length > 0 ? (
            <div>
              <Input label="Select the Wallet you want using">
                <select
                  onChange={(event: any) => {
                    if (event.target.value === "null") {
                      setSelectedWallet("null");
                    } else {
                      setSelectedWallet(
                        qKeepixWallets.data.find(
                          (x: any) => x.address === event.target.value
                        )
                      );
                    }
                  }}
                >
                  <option value="null">Select</option>
                  {qKeepixWallets.data.map((item: any, index: number) => (
                    <option key={index} value={item.address}>
                      {item.address}
                    </option>
                  ))}
                </select>
              </Input>
              <br></br>
              <br></br>
              <Btn
                onClick={() => {
                  handleSelectWalletWithSecret(wallet);
                }}
                disabled={wallet === "null"}
              >
                Select
              </Btn>
            </div>
          ) : (
            ""
          )}
          {qKeepixWallets.data && qKeepixWallets.data.length === 0 ? (
            <div>
              {labelIfEmptyWalletList}
              <br></br>
              <br></br>
              <Btn href="/wallets">Wallets Page</Btn>
            </div>
          ) : (
            ""
          )}
        </Popin>
      )}
    </>
  );
};
