import { KEEPIX_API_URL } from "../constants";
import { MonitoringInformation } from "../model/CPU";
import {
  KeepixInformation,
  KeepixSettings,
  KeepixUpdateState,
} from "../model/Keepix";
import { AsyncQueryResponse, Plugin } from "../model/Plugin";
import { TaskProgress } from "../model/Task";

// Plugin
export const getPluginCollection = async () =>
  request<Plugin[]>(`${KEEPIX_API_URL}/plugins/list`, "getPluginCollection");

export const postPluginInstall = async (
  id: string,
  version: string,
  body: any
) =>
  request<AsyncQueryResponse>(
    `${KEEPIX_API_URL}/plugins/${id}/install?version=${version}`,
    "postPluginInstall",
    "POST",
    body
  );

export const postPluginUpdate = async (
  id: string,
  version: string,
  body: any
) =>
  request<AsyncQueryResponse>(
    `${KEEPIX_API_URL}/plugins/${id}/update?version=${version}`,
    "postPluginUpdate",
    "POST",
    body
  );

export const postPluginUninstall = async (id: string) =>
  request<AsyncQueryResponse>(
    `${KEEPIX_API_URL}/plugins/${id}/uninstall`,
    "postPluginUninstall",
    "POST"
  );

// Task
export const getTaskProgress = async (pluginId: string, taskId: string) =>
  request<TaskProgress>(
    `${KEEPIX_API_URL}/plugins/${pluginId}/watch/task/${taskId}`,
    "getTaskProgress"
  );

// Keepix
export const getKeepixInformation = async () =>
  request<KeepixInformation>(
    `${KEEPIX_API_URL}/app/keepix-information`,
    "getKeepixInformation"
  );

export const postKeepixUpdateState = async (version: string) =>
  request<KeepixUpdateState>(
    `${KEEPIX_API_URL}/app/update?version=${version}`,
    "postKeepixUpdateState",
    "POST"
  );

export const getKeepixPlateformId = async () =>
  request<string>(`${KEEPIX_API_URL}/app/platform-id`, "getKeepixPlateformId");

// export const getKeepixSettings = async () =>
//   request<KeepixSettings>(`${KEEPIX_API_URL}/settings`, "getKeepixSettings");
export const getKeepixSettings = async () =>
  request<KeepixSettings>(
    `${KEEPIX_API_URL}/settings`,
    "keepingSettings",
    "GET"
  );

export const getKeepixPostSettings = async (body: any) =>
  request<boolean>(
    `${KEEPIX_API_URL}/settings`,
    "keepingPostSettings",
    "POST",
    body
  );

export const getKeepixWallets = async () =>
  requestWithHeaders<any>(`${KEEPIX_API_URL}/wallets`, "getKeepixWallets");

export const getKeepixWalletsWithBalances = async () =>
  requestWithHeaders<any>(`${KEEPIX_API_URL}/wallets/list-with-balances`, "getKeepixWalletsWithBalances");

export const getKeepixWalletsTypes = async () =>
  request<any>(`${KEEPIX_API_URL}/wallets/types`, "getKeepixWalletsTypes");

export const addKeepixWallet = async (body: any) =>
  request<any>(
    `${KEEPIX_API_URL}/wallets/new`,
    "postKeepixWalletNew",
    "POST",
    body
  );

export const importKeepixWallet = async (body: any) =>
  request<any>(
    `${KEEPIX_API_URL}/wallets/import`,
    "postKeepixWalletImport",
    "POST",
    body
  );

export const removeKeepixWallet = async (body: any) =>
  request<any>(
    `${KEEPIX_API_URL}/wallets/delete`,
    "postKeepixWalletRemove",
    "POST",
    body
  );

export const executeKeepixSshCommand = async (body: any) =>
  request<any>(
    `${KEEPIX_API_URL}/ssh`,
    "postKeepixSshCommand",
    "POST",
    body
  );

export const getKeepixWalletSecret = async (address: any) =>
  request<any>(
    `${KEEPIX_API_URL}/wallets/secret-data/${address}`,
    "getKeepixWalletSecret",
    "GET"
  );

export const refreshKeepixWallet = async (body: any) =>
  request<any>(
    `${KEEPIX_API_URL}/wallets/refresh`,
    "postKeepixWalletRefresh",
    "POST",
    body
  );

export const importKeepixTokenToWallet = async (body: any) =>
  request<any>(
    `${KEEPIX_API_URL}/wallets/import-token`,
    "postImportKeepixTokenToWallet",
    "POST",
    body
  );

export const sendKeepixSetupWallet = async (body: any) =>
  request<any>(
    `${KEEPIX_API_URL}/wallets/setup`,
    "postSendKeepixSetupWallet",
    "POST",
    body
  );

export const sendKeepixTokenOrCoinToWalletAddress = async (body: any) =>
  request<any>(
    `${KEEPIX_API_URL}/wallets/send`,
    "postSendKeepixTokenOrCoinToWalletAddress",
    "POST",
    body
  );

// Monitoring
export const getMonitoringInformation = async () =>
  request<MonitoringInformation>(
    `${KEEPIX_API_URL}/monitoring/keepix`,
    "getMonitoringInformation"
  );

// Functions
async function requestAsResponse<T>(
  url: string,
  name: string,
  method: "GET" | "POST" = "GET",
  body?: any
) {
  const response: Response = await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: method === "POST" ? JSON.stringify(body) : undefined,
  });

  if (!response.ok) {
    throw new Error(`${name} call failed.`);
  }

  return response;
}

async function requestWithHeaders<T>(
  url: string,
  name: string,
  method: "GET" | "POST" = "GET",
  body?: any
) {
  const response: Response = await requestAsResponse(url, name, method, body);

  const data: T = await response.json();
  return {
    data: data,
    headers: response.headers
  };
}

async function request<T>(
  url: string,
  name: string,
  method: "GET" | "POST" = "GET",
  body?: any
) {
  const response: Response = await requestAsResponse(url, name, method, body);

  const data: T = await response.json();
  return data;
}
