import "./PageBase.scss";
import { Icon } from "@iconify-icon/react";
import { AppBaseProps } from "../../model/Props";

export default function PageBase({
  title,
  subTitle,
  icon = "ph:house-simple",
  color,
  children,
  right,
}: AppBaseProps) {
  const style = { "--color": color } as React.CSSProperties;

  return (
    <>
      <header className="AppBase-header" style={style}>
        <div className="AppBase-headerIcon icon-app">
          <Icon icon={icon} />
        </div>
        <div className="AppBase-headerContent">
          <h1 className="AppBase-headerTitle">{title}</h1>
          {subTitle && <div className="AppBase-headerSubtitle">{subTitle}</div>}
        </div>
        {right && <div className="AppBase-headerRight">{right}</div>}
      </header>
      <div className="AppBase-content">{children}</div>
    </>
  );
}
