import { NavigationAction } from "../model/Navigation";

export const navigationData: {
  actions: NavigationAction[];
  secondayActions: NavigationAction[];
} = {
  actions: [
    {
      icon: "clarity:store-line",
      name: "Plugins Store",
      link: "/add",
      target: "",
    },
    {
      icon: "ph:wallet",
      name: "Wallets",
      link: "/",
      target: "",
    },
    {
      icon: "ph:terminal",
      name: "Terminal",
      link: "/terminal",
      target: "",
    },
  ],
  secondayActions: [
    {
      icon: "ph:sliders-horizontal",
      name: "Preferences",
      link: "/settings",
      target: "",
    },
  ],
};
