import { useEffect, useState } from "react";
import "../scss/pages/WalletsPage.scss";
import { Icon } from "@iconify/react";
import { useQuery } from "@tanstack/react-query";
import PageLayout from "../components/Page/PageLayout";
import PageBase from "../components/Page/PageBase";
import Btn from "../components/Btn/Btn";
import {
  addKeepixWallet,
  getKeepixWalletSecret,
  getKeepixWallets,
  getKeepixWalletsTypes,
  getKeepixWalletsWithBalances,
  importKeepixTokenToWallet,
  importKeepixWallet,
  refreshKeepixWallet,
  removeKeepixWallet,
  sendKeepixSetupWallet,
  sendKeepixTokenOrCoinToWalletAddress,
} from "../queries/api";
import copy from "copy-text-to-clipboard";
import Popin from "../components/Popin/Popin";
import Loader from "../components/Loader/Loader";
import BannerAlert from "../components/BannerAlert/BannerAlert";
import { Checkbox, Input } from "../components/Form/Form";
import { KEEPIX_API_URL } from "../constants";
import Logo from "../components/Logo/Logo";
import BigLoader from "../components/BigLoader/BigLoader";
import moment from 'moment';

/** Wallet Item */

const Wallet = ({ data, onChange }: any) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <tr
        className={`WalletsPage-item ${open ? "opened" : ""}`}
        onClick={toggleOpen}
      >
        <td width="1%" className="td-icon">
          {data.icon &&
            !data.icon.startsWith("http") &&
            !data.icon.startsWith("/wallets/") && (
              <Icon
                className="WalletsPage-item-icon"
                icon={data.icon ?? "fluent:wallet-credit-card-20-regular"}
              />
            )}
          {data.icon && data.icon.startsWith("http") && (
            <img
              className="WalletsPage-item-icon"
              src={data.icon}
              style={{ width: "32px", height: "32px" }}
            />
          )}
          {data.icon && data.icon.startsWith("/wallets/") && (
            <img
              className="WalletsPage-item-icon"
              src={`${KEEPIX_API_URL}${data.icon}`}
              style={{ width: "32px", height: "32px" }}
            />
          )}
          {!data.icon && (
            <img
              className="WalletsPage-item-icon"
              src={"fluent:wallet-credit-card-20-regular"}
            />
          )}
        </td>
        <td width="50%">
          <small>{data.type.toUpperCase()}</small>
          <strong>{data.name ?? "Wallet"}</strong>
          <WalletAddress data={data}></WalletAddress>
        </td>
        <td width="25%" className="price">
          <strong>
            {data.balance !== undefined ? data.balance : "???"}{" "}
            {data.nativeCoinName ?? ""}
          </strong>
          <small>
            {data.usd !== undefined
              ? `${Number(data.usd).toFixed(2)} $`
              : "???,?? $"}
          </small>
        </td>
        <td width="25%" className="chart">
          <div
            className="chart-info"
            data-status={
              Number(data.usd) !== 0 &&
              data?.analytics?.length >= 2 &&
              100 -
                (data.analytics[0].usd /
                  data.analytics[data.analytics.length - 1].usd) *
                  100 <
                0
                ? "danger"
                : "success"
            }
          >
            <Icon
              icon={
                Number(data.usd) !== 0 &&
                data?.analytics?.length >= 2 &&
                100 -
                  (data.analytics[0].usd /
                    data.analytics[data.analytics.length - 1].usd) *
                    100 <
                  0
                  ? "ph:chart-line-down"
                  : "ph:chart-line-up"
              }
            />
            <strong>
              {Number(data.usd) !== 0 && data?.analytics?.length >= 2
                ? `${
                    100 -
                      (data.analytics[0].usd /
                        data.analytics[data.analytics.length - 1].usd) *
                        100 >
                    0
                      ? "+"
                      : ""
                  }${(
                    100 -
                    (Number(data.analytics[0].usd) /
                      Number(data.analytics[data.analytics.length - 1].usd)) *
                      100
                  ).toFixed(2)}%`
                : "+0%"}
            </strong>
          </div>
        </td>
        <td width="1%" className="showDetail">
          {data.master === true && (<div style={{ position: 'absolute', top: '5px', right: '5px' }}>
            <Icon
                  icon="material-symbols:passkey"
              />
          </div>)}
          <Btn onClick={() => {}} icon={open ? "ph:eye-closed" : "ph:eye"}>
            {open ? "Hide details" : "Show details"}
          </Btn>
        </td>
      </tr>
      {open && (
        <tr className="WalletsPage-wrapper">
          <td colSpan={5}>
            <div
              className="WalletsPage-wrapper-content"
              style={{
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
            >
              <WalletAction wallet={data} onChange={onChange} />
            </div>
          </td>
        </tr>
      )}
      {open && (
        <>
          {data.tokens
            ? data.tokens
                .filter((x: any) => !x.hidden)
                .map((item: any, index: number) => (
                  <Token
                    key={index}
                    data={item}
                    firstAnalytic={
                      data.analytics &&
                      data.analytics[0]?.tokens?.find(
                        (t: any) => t.name === item.name
                      ) !== undefined
                        ? data.analytics[0]?.tokens.find(
                            (t: any) => t.name === item.name
                          )
                        : undefined
                    }
                    lastAnalytic={
                      data.analytics &&
                      data.analytics[data.analytics.length - 1]?.tokens?.find(
                        (t: any) => t.name === item.name
                      ) !== undefined
                        ? data.analytics[
                            data.analytics.length - 1
                          ]?.tokens.find((t: any) => t.name === item.name)
                        : undefined
                    }
                  ></Token>
                ))
            : []}
        </>
      )}
      {open && data.type !== "bitcoin" && (
        <>
          <tr className={`WalletsPage-item closed`}>
            <TokenAction wallet={data} />
          </tr>
        </>
      )}
    </>
  );
};

const WalletAddress = ({ data }: any) => {
  const [color, setColor] = useState<undefined | string>(undefined);

  return (
    <small>
      {`${data.title ?? ""}`}
      {data.address}{" "}
      <Icon
        icon="material-symbols:link"
        style={{
          fontSize: "17px",
          top: "1px",
          color: color,
          cursor: "pointer",
        }}
        onClick={(event: any) => {
          setColor("white");
          copy(data.address);
          event.stopPropagation();
          setTimeout(() => setColor(undefined), 200);
        }}
      />
    </small>
  );
};

/** Wallet Action Item */

const WalletAction = ({ wallet, onChange }: any) => {
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (refresh === true) {
      refreshKeepixWallet({ type: wallet.type, address: wallet.address }).then(
        (data) => {
          onChange();
          setRefresh(false);
        }
      );
    }
  }, [refresh]);

  return (
    <div className="btn-group">
      <Btn
        onClick={() => {
          setRefresh(true);
        }}
        icon="bx:refresh"
        disabled={refresh}
      >
        Refresh
      </Btn>
      <ReceiveToWallet wallet={wallet}></ReceiveToWallet>
      <SendTokenOrCoinTo wallet={wallet}></SendTokenOrCoinTo>
      <Btn onClick={() => {}} icon="ph:swap">
        Swap
      </Btn>
      {wallet.asMnemonic && <MnemonicWallet wallet={wallet}></MnemonicWallet>}
      <SecretWallet wallet={wallet}></SecretWallet>
      <RemoveWallet
        wallet={wallet}
        onChange={() => {
          onChange();
        }}
      ></RemoveWallet>
    </div>
  );
};

/** List Tokens */

const Token = ({ data, firstAnalytic, lastAnalytic }: any) => {
  return (
    <>
      <tr className={`WalletsPage-item closed`}>
        <td width="1%" className="td-icon" style={{ borderRadius: "0px" }}>
          {data.icon && !data.icon.startsWith("http") && (
            <Icon
              className="WalletsPage-item-icon"
              icon={data.icon ?? "fluent:wallet-credit-card-20-regular"}
            />
          )}
          {data.icon && data.icon.startsWith("http") && (
            <img
              className="WalletsPage-item-icon"
              src={data.icon}
              style={{ width: "32px", height: "32px" }}
            />
          )}
          {!data.icon && (
            <Icon
              className="WalletsPage-item-icon"
              icon={"fluent:wallet-credit-card-20-regular"}
            />
          )}
        </td>
        <td width="50%">
          <strong>{data.name ?? "Wallet"}</strong>
          <WalletAddress
            data={{ title: "Token Address: ", address: data.contractAddress }}
          ></WalletAddress>
        </td>
        <td width="25%" className="price">
          <strong>
            {data.balance !== undefined ? data.balance : "???"}{" "}
            {data.nativeCoinName ?? ""}
          </strong>
          <small>
            {data.usd !== undefined
              ? `${Number(data.usd).toFixed(2)} $`
              : "???,?? $"}
          </small>
        </td>
        <td width="25%" className="chart">
          <div
            className="chart-info"
            data-status={
              Number(data.usd) !== 0 &&
              firstAnalytic &&
              lastAnalytic &&
              100 - (firstAnalytic.usd / lastAnalytic.usd) * 100 < 0
                ? "danger"
                : "success"
            }
          >
            <Icon
              icon={
                Number(data.usd) !== 0 &&
                firstAnalytic &&
                lastAnalytic &&
                100 - (firstAnalytic.usd / lastAnalytic.usd) * 100 < 0
                  ? "ph:chart-line-down"
                  : "ph:chart-line-up"
              }
              style={{
                color:
                  Number(data.usd) !== 0 &&
                  firstAnalytic &&
                  lastAnalytic &&
                  100 - (firstAnalytic.usd / lastAnalytic.usd) * 100 < 0
                    ? "red"
                    : "green",
              }}
            />
            <strong>
              {Number(data.usd) !== 0 && firstAnalytic && lastAnalytic
                ? `${
                    100 - (firstAnalytic.usd / lastAnalytic.usd) * 100 > 0
                      ? "+"
                      : ""
                  }${(
                    100 -
                    (Number(firstAnalytic.usd) / Number(lastAnalytic.usd)) * 100
                  ).toFixed(2)}%`
                : "+0%"}
            </strong>
          </div>
        </td>
        <td
          width="1%"
          className="showDetail"
          style={{ width: "1%", borderRadius: "0px" }}
        >
          <div style={{ opacity: "0" }}>
            <Btn onClick={() => {}} icon={"ph:eye"}>
              {"Show details"}
            </Btn>
          </div>
        </td>
      </tr>
    </>
  );
};

/** Token Action */

const TokenAction = ({ wallet }: any) => {
  return (
    <td colSpan={5} style={{ width: "100%", textAlign: "center" }}>
      <ImportTokenToWallet wallet={wallet}></ImportTokenToWallet>
    </td>
  );
};

/** Setup Wallets */

const SetupWallets = ({ label = undefined, subLabel = undefined, btnLabel = 'Setup', successcb = () => {} }: any) => {
  const [loading, setLoading] = useState(false);
  const [postResult, setPostResult] = useState<any>(undefined);
  const [show, setShow] = useState(false);
  const [masterPassword, setMasterPassword] = useState('');
  const [passwordQuality, setPasswordQuality] = useState('Bad');

  const checkPasswordQuality = (password: string) => {
    if (password.length < 16) {
      setPasswordQuality('Bad');
      return ;
    }
    if (!/[0-9]/.test(password) || !/[a-zA-Z]/.test(password)) {
      setPasswordQuality('Well');
      return ;
    }
    setPasswordQuality('Nice');
    return ;
  }

  const postSetupWallets = (masterPassword: string) => {
    setLoading(true);
    sendKeepixSetupWallet({
      password: masterPassword
    }).then((result) => {
      setLoading(false);
      setPostResult(result);
      if (result.success === true) {
        successcb();
      }
    }).catch(() => {
      setLoading(false);
      setPostResult({ success: false, description: 'Api not found.'})
    });
  }

  return (<>
      {loading && <Loader />}
      {loading === false && (<>
        <div className={`card card-default`}>
          <div className="keepix-logo" style={{ height: '100px', width: '100px', textAlign: 'center', margin: 'auto' }}>
            <Logo text={false}></Logo>
          </div>
          <h1 className="AppBaseWelcome">{ label ? label : 'Welcome to your Keepix' }</h1>
          {(subLabel !== '' || subLabel === undefined) && (<h2 className="AppSubWelcome">{ subLabel ? subLabel : 'To generate your future wallets we need a password of at least 16 characters.' }</h2>)}
          <div style={{ position: 'relative' }}>
            <Input
              label=""
              name="masterPassword"
              required={true}
              displayLabel={false}
              overrideStyle={{ border: masterPassword !== '' ? (passwordQuality === 'Bad' ? '1px solid red' : passwordQuality === 'Well' ? '1px solid orange' : '1px solid green') : undefined }}
            >
              {show && (
                <input
                  onChange={(event: any) => {
                    setMasterPassword(event.target.value);
                    checkPasswordQuality(event.target.value);
                  }}
                  type="text"
                  id="masterPassword"
                  defaultValue={masterPassword}
                />
              )}

              {!show && (
                <input
                  onChange={(event: any) => {
                    setMasterPassword(event.target.value);
                    checkPasswordQuality(event.target.value);
                  }}
                  type="password"
                  id="masterPassword"
                  defaultValue={masterPassword}
                  placeholder="MasterPassword_82x"
                />
              )}
              
            </Input>
            <Icon icon={ !show ? 'mdi:eye' : 'octicon:eye-closed-16' } onClick={() => {
                setShow(!show);
              }} style={{ cursor: 'pointer', position: 'absolute', right: '24px', top: '24px' }} ></Icon>
          </div>
          {postResult !== undefined && postResult.success === false && (<>
            <BannerAlert status="danger">{ postResult.description }</BannerAlert>
          </>)}
          <br></br>
          <Btn
            fullWidth={true}
            onClick={() => {
              postSetupWallets(masterPassword);
            }}
            disabled={ passwordQuality === 'Bad' }
          >
            { btnLabel }
          </Btn>
        </div>
      </>)}
    </>);
};

/** List Wallets */

const ListWallets = ({ onQuery }: any) => {
  const qKeepixWallets = useQuery({
    queryKey: ["getKeepixWalletsWithBalances"],
    queryFn: getKeepixWalletsWithBalances,
    refetchOnWindowFocus: true,
  });

  useQuery({
    queryKey: ["UpdateWalletsDisplay"],
    queryFn: () => {
      onQuery(qKeepixWallets);
      return new Promise((resolve) => {resolve(true)});
    },
    refetchInterval: 1000,
    enabled: qKeepixWallets?.data !== undefined
  });

  return (
    <div className="WalletsPage">
      {!qKeepixWallets.data && <Loader />}
      <table className="WalletsPage-table">
        <tbody style={{ display: "block" }}>
          {qKeepixWallets.data
            && qKeepixWallets.data.headers.get('Master-Is-Setup') == 'true'
            && moment(Number(qKeepixWallets.data.headers.get('Master-TimeStamp-Expiration'))).isAfter(moment())
            && (<>
            {qKeepixWallets.data.data
                .map((item: any, index: number) => (
                  <Wallet
                    key={index}
                    data={item}
                    onChange={() => {
                      qKeepixWallets.refetch();
                    }}
                  ></Wallet>
                ))}
            </>)}

          {qKeepixWallets.data
            && qKeepixWallets.data.headers.get('Master-Is-Setup') == 'false'
            && (<>
              <SetupWallets successcb={() => {
                qKeepixWallets.refetch();
              }}></SetupWallets>
            </>)}

          {qKeepixWallets.data
            && qKeepixWallets.data.headers.get('Master-Is-Setup') == 'true'
            && moment(Number(qKeepixWallets.data.headers.get('Master-TimeStamp-Expiration'))).isBefore(moment())
            && (<>
              <SetupWallets label="Welcome Back!" subLabel="" btnLabel="Unlock" successcb={() => {
                qKeepixWallets.refetch();
              }}></SetupWallets>
            </>)}
        </tbody>
      </table>
    </div>
  );
};

const AddWallet = ({ onChange }: any) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postResult, setPostResult] = useState<any>(undefined);
  const [type, setType] = useState<any>("bitcoin");

  const qKeepixWalletsTypes = useQuery({
    queryKey: ["keepingWalletsTypes"],
    queryFn: getKeepixWalletsTypes,
  });

  const sendAddWallet = async (body: any) => {
    setLoading(true);
    const result = await addKeepixWallet(body);
    setLoading(false);
    if (result.success === true) {
      setOpen(false);
      onChange();
    } else {
      setPostResult(result);
    }
  };

  return (
    <>
      <Btn
        onClick={() => {
          setOpen(true);
        }}
        icon="ph:wallet"
        status="success"
      >
        New wallet
      </Btn>
      {open && (
        <>
          <Popin title="Create a new Wallet" close={() => setOpen(false)}>
            {(loading || !qKeepixWalletsTypes.data) && <Loader />}
            {postResult && postResult?.success === false && (
              <BannerAlert status="danger">
                {postResult?.description}
              </BannerAlert>
            )}
            {qKeepixWalletsTypes.data && (
              <Input label="Select the type of the wallet">
                <select
                  value={type}
                  onChange={(event: any) => {
                    setType(event.target.value);
                  }}
                >
                  {qKeepixWalletsTypes.data.map((type: any, key: string) => (
                    <option value={type.id} key={key}>
                      {type.name
                        .toLowerCase()
                        .replace(/(?<!\S)\S/gu, (match: string) => match.toUpperCase())}
                    </option>
                  ))}
                </select>
              </Input>
            )}
            <Btn onClick={() => sendAddWallet({ type: type })}>Create</Btn>
          </Popin>
        </>
      )}
    </>
  );
};

const ImportWallet = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postResult, setPostResult] = useState<any>(undefined);
  const [type, setType] = useState<string>("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [privateKey, setPrivateKey] = useState<any>(undefined);
  const [mnemonic, setMnemonic] = useState<any>(undefined);
  const [method, setMethod] = useState<"privateKey" | "mnemonic">("privateKey");

  const qKeepixWalletsTypes = useQuery({
    queryKey: ["keepingWalletsTypes"],
    queryFn: getKeepixWalletsTypes,
  });

  const sendImportWallet = async (body: any) => {
    setLoading(true);
    const result = await importKeepixWallet(body);
    setLoading(false);
    if (result.success === true) {
      setOpen(false);
      window.location.reload();
    } else {
      setPostResult(result);
    }
  };

  useEffect(() => {
    if (
      (privateKey !== undefined && privateKey !== "") ||
      (mnemonic !== undefined && mnemonic !== "")
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [privateKey, mnemonic]);

  return (
    <>
      <Btn
        onClick={() => {
          setOpen(true);
        }}
        icon="ph:wallet"
        status="warning"
      >
        Import wallet
      </Btn>
      {open && (
        <>
          <Popin title="Import existing Wallet" close={() => setOpen(false)}>
            {(loading || !qKeepixWalletsTypes.data) && <Loader />}
            {postResult && postResult?.success === false && (
              <BannerAlert status="danger">
                {postResult?.description}
              </BannerAlert>
            )}
            {qKeepixWalletsTypes.data && (
              <>
                <Input label="Select the type of the wallet">
                  <select
                    value={type}
                    onChange={(event: any) => {
                      setType(event.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    {qKeepixWalletsTypes.data.map(
                      (type: any, key: string) => (
                        <option value={type.id} key={key}>
                          {type.name
                            .toLowerCase()
                            .replace(/(?<!\S)\S/gu, (match: string) =>
                              match.toUpperCase()
                            )}
                        </option>
                      )
                    )}
                  </select>
                </Input>
              </>
            )}
            {qKeepixWalletsTypes.data && type !== "" && (
              <Checkbox
                key="method"
                name="method"
                label="Method of importation"
                enabled="By PrivateKey"
                disabled="By Sentence Pass"
                defaultValue={true}
                onChange={(event: boolean) => {
                  setMnemonic(undefined);
                  setPrivateKey(undefined);
                  setMethod(event ? "privateKey" : "mnemonic");
                }}
                style={{
                  width: "100%",
                  "--cursor": "50%",
                  borderRadius: "10px",
                }}
              ></Checkbox>
            )}
            {qKeepixWalletsTypes.data &&
              method === "privateKey" &&
              qKeepixWalletsTypes.data.map((x: any) => x.id).includes(type) && (
                <Input
                  label="Private Key"
                  name="privateKey"
                  icon="material-symbols:edit"
                  required={true}
                >
                  <input
                    onChange={(event: any) => {
                      setPrivateKey(event.target.value);
                    }}
                    type="text"
                    id="privateKey"
                    defaultValue=""
                    placeholder="Enter the Private Key Here"
                  />
                </Input>
              )}
            {qKeepixWalletsTypes.data &&
              method === "mnemonic" &&
              qKeepixWalletsTypes.data.map((x: any) => x.id).includes(type) && (
                <Input
                  label="Sentence Pass (Mnemonic)"
                  name="mnemonic"
                  icon="material-symbols:edit"
                  required={true}
                >
                  <input
                    onChange={(event: any) => {
                      setMnemonic(event.target.value);
                    }}
                    type="text"
                    id="mnemonic"
                    defaultValue=""
                    placeholder="Enter the Sentence Here"
                  />
                </Input>
              )}
            <Btn
              onClick={() =>
                sendImportWallet({
                  type: type,
                  privateKey: privateKey,
                  mnemonic: mnemonic,
                })
              }
              disabled={!formIsValid}
            >
              Import
            </Btn>
          </Popin>
        </>
      )}
    </>
  );
};

const ReceiveToWallet = ({ wallet }: any) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Btn
        onClick={() => {
          setOpen(true);
        }}
        icon="ph:arrow-circle-down"
        status="success"
      >
        Receive
      </Btn>
      {open && (
        <>
          <Popin title="Receive" close={() => setOpen(false)}>
            Your Wallet Address: {wallet.address}
            <Btn onClick={() => copy(wallet.address)}>
              Copy the Wallet Address
            </Btn>
          </Popin>
        </>
      )}
    </>
  );
};

const SecretWallet = ({ wallet }: any) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getWalletSecret = async (body: any) => {
    setErrorMessage('');
    setLoading(true);
    const result = await getKeepixWalletSecret(body);
    setLoading(false);
    if (result.success !== false) {
      if (wallet.type !== "bitcoin") {
        copy(result.wallet.privateKey);
      } else {
        copy(JSON.stringify(result.wallet.privateKey));
      }
      setOpen(false);
    } else {
      setErrorMessage(result.description);
    }
  };

  return (
    <>
      <Btn
        onClick={() => {
          setOpen(true);
        }}
        icon="ph:key"
        status="warning"
      >
        Secret
      </Btn>
      {open && (
        <>
          <Popin title="Secret" close={() => setOpen(false)}>
            Copy to your clipboard the secret key of your Wallet Address:{" "}
            {wallet.address}
            {loading && <Loader />}
            {errorMessage !== '' && <BannerAlert status="danger">{errorMessage}</BannerAlert>}
            <Btn
              onClick={() => getWalletSecret(wallet.address)}
              disabled={loading}
            >
              Copy Secret
            </Btn>
          </Popin>
        </>
      )}
    </>
  );
};

const MnemonicWallet = ({ wallet }: any) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getWalletMnemonic = async (body: any) => {
    setErrorMessage('');
    setLoading(true);
    const result = await getKeepixWalletSecret(body);
    setLoading(false);
    if (result.success !== false) {
      copy(result.wallet.mnemonic);
      setOpen(false);
    } else {
      setErrorMessage(result.description);
    }
  };

  return (
    <>
      <Btn
        onClick={() => {
          setOpen(true);
        }}
        icon="carbon:character-sentence-case"
        status="warning"
      >
        Sentence
      </Btn>
      {open && (
        <>
          <Popin title="Sentence" close={() => setOpen(false)}>
            Copy to your clipboard the sentence pass of your Wallet Address:{" "}
            {wallet.address}
            {loading && <Loader />}
            {errorMessage !== '' && <BannerAlert status="danger">{errorMessage}</BannerAlert>}
            <Btn
              onClick={() => getWalletMnemonic(wallet.address)}
              disabled={loading}
            >
              Copy Sentence
            </Btn>
          </Popin>
        </>
      )}
    </>
  );
};

const RemoveWallet = ({ wallet, onChange }: any) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postResult, setPostResult] = useState<any>(undefined);

  const sendRemoveWallet = async (body: any) => {
    setLoading(true);
    const result = await removeKeepixWallet(body);
    setLoading(false);
    setPostResult(result);
  };

  return (
    <>
      <Btn
        onClick={() => {
          setOpen(true);
        }}
        icon="ph:trash"
        status="danger"
      >
        Remove wallet
      </Btn>
      {open && (
        <>
          <Popin
            title="Remove Wallet"
            close={() => {
              if (postResult?.success === true) {
                onChange();
              }
              setOpen(false);
              setPostResult(undefined);
            }}
          >
            {!postResult && !loading && (
              <>
                Are you sure you want to delete the wallet?
                <br></br>
                <sup>"Deleted Wallets is saved on one separated file"</sup>
                <br></br>
                <span>{wallet.address}</span>
              </>
            )}
            {loading && <Loader />}
            {postResult && postResult?.success === false && (
              <BannerAlert status="danger">
                {postResult?.description}
              </BannerAlert>
            )}
            {postResult && postResult?.success === true && (
              <BannerAlert status="success">
                {postResult?.description}
              </BannerAlert>
            )}
            {!postResult && !loading && (
              <Btn
                status="danger"
                onClick={() =>
                  sendRemoveWallet({
                    type: wallet.type,
                    address: wallet.address,
                  })
                }
              >
                Remove
              </Btn>
            )}
          </Popin>
        </>
      )}
    </>
  );
};

const ImportTokenToWallet = ({ wallet }: any) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contractAddress, setContractAddress] = useState("");
  const [postResult, setPostResult] = useState<any>(undefined);

  const sendImportTokenToWallet = async (body: any) => {
    setLoading(true);
    const result = await importKeepixTokenToWallet(body);
    setLoading(false);
    if (result && result.success) {
      setOpen(false);
      window.location.reload();
    } else {
      setPostResult(result);
    }
  };

  return (
    <>
      <Btn
        onClick={() => {
          setOpen(true);
        }}
        icon="material-symbols:add"
        status="success"
      >
        Import Tokens
      </Btn>
      {open && (
        <>
          <Popin title="Import Tokens" close={() => setOpen(false)}>
            Paste the contract Address of the token into the field.
            {loading && <Loader />}
            {postResult && postResult?.success === false && (
              <BannerAlert status="danger">
                {postResult?.description}
              </BannerAlert>
            )}
            <Input
              label="Contract Token Address"
              name="contractAddress"
              icon="material-symbols:edit"
              required={true}
            >
              <input
                onChange={(event: any) => {
                  setContractAddress(event.target.value);
                }}
                type="text"
                id="contractAddress"
                defaultValue=""
                placeholder="Enter the Contract Token Address Here"
              />
            </Input>
            <Btn
              onClick={() =>
                sendImportTokenToWallet({
                  address: wallet.address,
                  type: wallet.type,
                  contractAddress: contractAddress,
                })
              }
              disabled={contractAddress === ""}
            >
              Import
            </Btn>
          </Popin>
        </>
      )}
    </>
  );
};

const SendTokenOrCoinTo = ({ wallet }: any) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receiverAddress, setReceiverAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [name, setName] = useState(wallet.nativeCoinName);
  const [postResult, setPostResult] = useState<any>(undefined);
  const [hash, setTxHash] = useState("");

  useEffect(() => {
    if (name === wallet.nativeCoinName) {
      setAmount(wallet.balance);
    } else {
      const token = wallet.tokens.find((x: any) => x.name === name);
      console.log(token);
      setAmount(token.balance);
    }
  }, [name]);

  const sendTokenOrCoinToAddress = async (body: any) => {
    setLoading(true);
    const result = await sendKeepixTokenOrCoinToWalletAddress(body);
    setLoading(false);
    if (result && result.success) {
      setTxHash(result.description);
    } else {
      setPostResult(result);
    }
  };

  return (
    <>
      <Btn
        onClick={() => {
          setOpen(true);
        }}
        icon="ph:arrow-circle-up"
      >
        Send
      </Btn>
      {open && (
        <>
          <Popin
            title="Send Coin or Tokens"
            close={() => {
              setOpen(false);
              setTxHash("");
            }}
          >
            {loading && <Loader />}
            {postResult && postResult?.success === false && (
              <BannerAlert status="danger">
                {postResult?.description}
              </BannerAlert>
            )}
            {hash !== "" && (
              <div style={{ color: "#cbcbcb" }}>
                Transaction Sent hash:{" "}
                <WalletAddress data={{ address: hash }}></WalletAddress>
              </div>
            )}
            {hash === "" ? (
              <div>
                Paste the contract Address of the token into the field.
                <br></br>
                <br></br>
                <Input label="Select the token">
                  <select
                    value={name}
                    onChange={(event: any) => {
                      setName(event.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    <option value={wallet.nativeCoinName}>
                      {wallet.nativeCoinName}
                    </option>
                    {wallet.tokens &&
                      wallet.tokens
                        .filter((x: any) => !x.hidden)
                        .map((item: any, index: number) => (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                  </select>
                </Input>
                <br></br>
                <br></br>
                <Input
                  label="Amount"
                  name="amount"
                  icon="material-symbols:edit"
                  required={true}
                >
                  <input
                    onChange={(event: any) => {
                      setAmount(event.target.value);
                    }}
                    type="integer"
                    id="amount"
                    defaultValue={amount}
                    value={amount}
                    placeholder="0.512"
                  />
                </Input>
                <Input
                  label="Receiver Address"
                  name="receiverAddress"
                  icon="material-symbols:edit"
                  required={true}
                >
                  <input
                    onChange={(event: any) => {
                      setReceiverAddress(event.target.value);
                    }}
                    type="text"
                    id="receiverAddress"
                    defaultValue=""
                    placeholder="Receiver Address here"
                  />
                </Input>
                <br></br>
                <Btn
                  onClick={() =>
                    sendTokenOrCoinToAddress({
                      address: wallet.address,
                      type: wallet.type,
                      name: name,
                      to: receiverAddress,
                      amount: amount,
                    })
                  }
                  disabled={
                    receiverAddress === "" || amount === "" || name === ""
                  }
                >
                  Send
                </Btn>
              </div>
            ) : (
              ""
            )}
          </Popin>
        </>
      )}
    </>
  );
};

/** App Wallets */

export default function WalletsPage() {
  const [listOfWalletQuery, setListOfWalletQuery] = useState<any>(undefined);

  return (
    <PageLayout>
      <PageBase
        title="Wallets"
        subTitle="Wallets connected to your Keepix"
        icon="ph:wallet"
        right={
          (listOfWalletQuery?.data && listOfWalletQuery.data?.headers.get('Master-Is-Setup') == 'true'
          && moment(Number(listOfWalletQuery.data.headers.get('Master-TimeStamp-Expiration'))).isAfter(moment())
          && (<div>
            <ImportWallet />
            <AddWallet
              onChange={() => {
                if (listOfWalletQuery !== undefined)
                  listOfWalletQuery.refetch();
              }}
            /></div>)
          )
        }
      >
        <ListWallets
          onQuery={(q: any) => {
            setListOfWalletQuery(q);
          }}
        />
      </PageBase>
    </PageLayout>
  );
}
