import {
  ReactNode,
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";

export type Plugin = {
  id: string;
  title: string;
  subTitle: string;
  installed: boolean;
  icon: string;
  version: string;
  latestVersion?: string;
  packageName?: string;
  repositoryUrl?: string;
};

type PluginsListContextValue = {
  pluginsList: Plugin[];
  setPluginsList: Dispatch<SetStateAction<Plugin[]>>;
};

export const PluginsListContext = createContext<
  PluginsListContextValue | undefined
>(undefined);

type Props = {
  children: ReactNode;
};

export const usePluginsListContext = () => {
  const context = useContext(PluginsListContext);
  if (context === undefined) {
    throw new Error(
      "usePluginsListContext must be inside a PluginsListProvider"
    );
  }
  return context;
};

export default function PluginsListProvider({ children }: Props) {
  const [pluginsList, setPluginsList] = useState<
    PluginsListContextValue["pluginsList"]
  >([]);

  return (
    <PluginsListContext.Provider value={{ pluginsList, setPluginsList }}>
      {children}
    </PluginsListContext.Provider>
  );
}
