import { Dispatch, SetStateAction } from "react";
import { APIState } from "../context/api/APIProvider";

export const getErrorMsg = (e: unknown) => {
  if (typeof e === "string") {
    return e;
  }

  if (e instanceof Error) {
    return e.message;
  }

  return "Error";
};

export const safeFetch = async (
  url: string,
  method: "GET" | "POST" = "GET",
  setAPIState: Dispatch<SetStateAction<APIState | null>>,
  data?: any
) => {
  let res = new Response();
  let options: any = {
    method,
    headers: {
      "Cache-Control": "no-cache",
    },
  };
  if (data) {
    options.body = JSON.stringify(data);
  }
  try {
    res = await fetch(url, options);
  } catch (error) {
    console.error(error);
    setAPIState("WAITING");
    await new Promise((r) => setTimeout(r, 3000));

    try {
      res = await fetch(url, options);
    } catch (error) {
      setAPIState("UNREACHABLE");
      throw new Error("API not reachable");
    }
  }

  setAPIState("UP");

  return res;
};

export const evalIsValid = (value: any, validator: string) => {
  try {
    const result = eval(
      new Function("v", `with (v) { return (${validator}) }`)(value)
    );
    return result === true;
  } catch (e) {
    return false;
  }
};


export const isElectron = () => {
  // Renderer process
  if (typeof window !== 'undefined' && typeof window.process === 'object' && (window.process as any).type === 'renderer') {
      return true;
  }

  // Main process
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
      return true;
  }

  // Detect the user agent when the `nodeIntegration` is set to true
  if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.includes('Electron')) {
      return true;
  }

  return false;
}
