import PageLayout from "../components/Page/PageLayout";
import PageBase from "../components/Page/PageBase";
import { useQuery } from "@tanstack/react-query";
import BannerAlert from "../components/BannerAlert/BannerAlert";
import { Form } from "../components/Form/Form";
import Loader from "../components/Loader/Loader";
import { getKeepixPostSettings, getKeepixSettings } from "../queries/api";
import { DynamicInput } from "../components/Form/DynamicInput";
import { useEffect, useState } from "react";
import Btn from "../components/Btn/Btn";

export default function SettingsPage() {
  const qKeepixSettings = useQuery({
    queryKey: ["keepingSettings"],
    queryFn: getKeepixSettings,
  });

  const [settings, setSettings] = useState(
    qKeepixSettings.data?.settings || {}
  );

  const [isValidInputs, setIsValidInputs] = useState<any[]>([]);

  useEffect(() => {
    if (qKeepixSettings.isSuccess && qKeepixSettings.data) {
      setSettings(qKeepixSettings.data.settings || {});
    }
  }, [qKeepixSettings.isSuccess, qKeepixSettings.data]);

  const handleInputChange = (key: string, value: any, isValid: boolean = true) => {
    setSettings((prevSettings: any) => ({
      ...prevSettings,
      [key]: value,
    }));
    setIsValidInputs((prevValue: any) => {
      const valuesWithoutLastValidState = prevValue.filter((x: any) => x.key !== key);
      return [
        ... valuesWithoutLastValidState,
        { key: key, isValid: isValid }
      ];
    });
  };

  const checkIsValidForm = () => {
    return isValidInputs.reduce((acc: boolean, v: any) => {
      if (!acc) return acc;
      return v.isValid;
    }, true);
  }

  return (
    <PageLayout>
      <PageBase
        title="Settings"
        subTitle="Define your Keepix preferences"
        icon="ph:sliders-horizontal"
      >
        <Form>
          {/* <h2 className="h2">Preferences Keepix</h2> */}

          {qKeepixSettings.isLoading && <Loader />}
          {qKeepixSettings.isError && (
            <BannerAlert status="danger">
              {qKeepixSettings.error.message}
            </BannerAlert>
          )}

          {qKeepixSettings.data && (
            <>
              {qKeepixSettings.data.formInputs.map(
                (formInput, i) =>
                  typeof settings[formInput.key] !== "undefined" && (
                    <DynamicInput
                      input={formInput}
                      key={i}
                      defaultValue={settings[formInput.key]}
                      onChange={(value, isValid) =>
                        handleInputChange(formInput.key, value, isValid)
                      }
                    />
                  )
              )}
            </>
          )}
        </Form>
        <Btn
          onClick={async () => {
            const result = await getKeepixPostSettings(settings);
            if (result) {
              await qKeepixSettings.refetch();
            }
          }}
          icon="ph:upload"
          status="success"
          disabled={!checkIsValidForm()}
        >
        Validate Changes
        </Btn>
      </PageBase>
    </PageLayout>
  );
}
