import Iframe from "react-iframe";
import { useState, useEffect } from "react";
import "../scss/pages/TerminalPage.scss";
import PageBase from "../components/Page/PageBase";
import PageLayout from "../components/Page/PageLayout";
import Btn from "../components/Btn/Btn";
import { TerminalUi } from "keepix-react-simple-terminal-ui";
import "keepix-react-simple-terminal-ui/dist/styles.css"
import { executeKeepixSshCommand } from "../queries/api";

function TerminalZone() {
  const initialFeed =  [
  '--------------------------------------------------',
  <>&nbsp;&nbsp;____&nbsp;&nbsp;__.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.__</>,
  <>&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|/&nbsp;_|____&nbsp;&nbsp;&nbsp;____&nbsp;______&nbsp;|__|__&nbsp;&nbsp;___</>,
  <>&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;_/&nbsp;__&nbsp;\_/&nbsp;__&nbsp;\\____&nbsp;\|&nbsp;&nbsp;\&nbsp;&nbsp;\/&nbsp;&nbsp;/</>,
  <>&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;\&nbsp;&nbsp;___/\&nbsp;&nbsp;___/|&nbsp;&nbsp;|_|&nbsp;|&nbsp;&nbsp;|&gt;&nbsp;&nbsp;&nbsp;&nbsp;&lt; </>,
  <>&nbsp;|____|__&nbsp;\___&nbsp;&nbsp;&gt;\___&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;__/|__/__/\_&nbsp;\</>,
  <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\/&nbsp;&nbsp;&nbsp;\/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\/|&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\/ Keepix SSH</>,
  '-----------------------|/--------------------------',
  <>Welcome on the ssh <b style={{ color: 'red' }}>Terminal</b>.</>
  ];
  
  return (<>
  <TerminalUi
            initialFeed={initialFeed}
            prompt='[keepix@localhost]$ '
            onCommand={async (cmd) => {
              // console.log(cmd);
              return new Promise((resolve) => {
                executeKeepixSshCommand({ cmd: cmd }).then((result) => {
                  resolve(result.success ? (result.result.stdout !== '' ? result.result.stdout : result.result.stderr) : result.description);
                }).catch(() => {
                  resolve('Disconnected please try later ...');
                });
              });
            }}
            blinkerChar={"_"}
            frame={"None"}
            commandNotFoundMessage={(cmd) => {
              return `Sorry, I can't find '${cmd}'`;
            }}
            style={{ maxHeight: '400px' }}
           />
  </>);
}

// function TerminalZone() {
//   const prompt = /\$\>/gm;
//   const [textValue, setTextValue] = useState('$>');

//   const addValueOnTextArea = (value: string) => {
//     setTextValue(textValue + value);
//   }

//   const executeCommand = () => {
//     const val = textValue;
//     const promptLine = val.split('\n')[val.split('\n').length - 1];
//     const cmd = promptLine.split(prompt).filter(x => x !== '')[0];
//     if (cmd !== '' && cmd !== undefined) {
//       addValueOnTextArea('\n');
//       setTimeout(() => {
//         addValueOnTextArea('NINJA');
//       }, 3000);
//       setTimeout(() => {
//         addValueOnTextArea('\n$>');
//       }, 5000);
//     } else {
//       addValueOnTextArea('\n$>');
//     }
//   }

//   return (<>
//     <textarea id="terminalContent"
//       value={textValue}
//       onKeyDown={(e) => {
//       if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
//         console.log('KSKSKS');
//         e.preventDefault();
//         e.stopPropagation();
//         return;
//       }
//       if (e.key === 'Enter') {
//         executeCommand()
//         e.preventDefault();
//         e.stopPropagation();
//       }
//     }} onChange={(e) => {
//       setTextValue(e.target.value);
//     }}></textarea>
//   </>);
// }

export default function TerminalPage() {
  // Initialize the URL with a placeholder value
  const [iframeUrl, setIframeUrl] = useState("");

  // Function to update the iframe URL with the current host and protocol
  // const updateIframeUrl = () => {
  //   const currentHost = window.location.hostname;
  //   const currentProtocol = "https:"; //only available
  //   const sshPort = 12042;
  //   setIframeUrl(
  //     `${currentProtocol}//${
  //       currentHost.split(":")[0]
  //     }:${sshPort}/ssh/host/${currentHost}?${Math.random()}`
  //   );
  //   console.log(
  //     `${currentProtocol}//${
  //       currentHost.split(":")[0]
  //     }:${sshPort}/ssh/host/${currentHost}?${Math.random()}`
  //   );
  // };

  // Call the function to update the iframe URL when the component mounts (on the client-side)
  // useEffect(() => {
  //   updateIframeUrl();
  // }, []);

  return (
    <PageLayout>
      <PageBase
        title="Terminal"
        subTitle="Direct connection to your Keepix terminal, for the brave!"
        icon="ph:terminal"
      >
        <TerminalZone></TerminalZone>
      </PageBase>
    </PageLayout>
  );
}
