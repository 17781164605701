import "./Form.scss";
import "./DynamicInput.scss";
import { DynamicInputProps, DynamicTypes } from "../../model/Form";
import { Checkbox, Input } from "./Form";
import Btn from "../Btn/Btn";
import { evalIsValid } from "../../lib/utils";

const getDefaultValueByType = (type: DynamicTypes) => {
  switch (type) {
    case "checkbox-2":
      return false;
    case "blockchainTypeSelect":
      return "ethereum";
    case "inputText":
      return "";
    case "inputNumber":
      return 0;
  }

  return;
};

const onChangeWithValidation = (input: any, e: any, onChange: any) => {
  const isValid = input.validator
    ? evalIsValid({ value: e.target.value }, input.validator)
    : true;
  if (onChange) {
    onChange(e.target.value, isValid);
  }
  if (!isValid) {
    e.target.classList.add("invalid");
    e.target.classList.remove("valid");
  } else {
    e.target.classList.remove("invalid");
    e.target.classList.add("valid");
  }
};

export const DynamicInput = ({
  input,
  defaultValue,
  onChange,
}: {
  input: DynamicInputProps;
  defaultValue: any;
  onChange?: (value: any, isValid?: boolean) => void;
}) => {
  const handleAddRow = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (input.type !== "list" || !onChange) {
      return;
    }

    const newRow: any = {};
    input.inputs.forEach((subInput: any) => {
      if (subInput.type !== "list") {
        newRow[subInput.key] =
          subInput.defaultValue || getDefaultValueByType(subInput.type);
      }
    });

    onChange([...(defaultValue || []), newRow]);
  };

  return (
    <>
      <div className="DynamicInput" data-key={input.key}>
        {input.type === "list" && (
          <>
            <div className="DynamicInput-title h2">{input.label}</div>
            <div className="DynamicInput-list">
              {defaultValue &&
                defaultValue.map((item: any, index: number) => (
                  <div className="DynamicInput-list-row" key={index}>
                    {input.inputs.map(
                      (subInput, i) =>
                        typeof item[subInput.key] !== "undefined" && (
                          <DynamicInput
                            input={subInput}
                            key={i}
                            defaultValue={item[subInput.key]}
                            onChange={(value, isValid) => {
                              const updatedItem = {
                                ...item,
                                [subInput.key]: value,
                              };
                              const updatedList = [...defaultValue];
                              updatedList[index] = updatedItem;
                              onChange && onChange(updatedList, isValid);
                            }}
                          />
                        )
                    )}
                  </div>
                ))}
            </div>
            {input.add === true && (
              <Btn
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  handleAddRow(e)
                }
              >
                Add
              </Btn>
            )}
          </>
        )}

        {input.type === "checkbox-2" && (
          <Checkbox
            key={input.key}
            name={input.key}
            label={input.label}
            enabled={input.true}
            disabled={input.false}
            defaultValue={defaultValue}
            onChange={(value) => onChange && onChange(value)}
          />
        )}

        {input.type === "inputText" && (
          <Input label={input.label} disabled={input.disabled}>
            <input
              type="text"
              id={input.key}
              value={defaultValue}
              disabled={input.disabled}
              onChange={(e) => {
                onChangeWithValidation(input, e, onChange);
              }}
            />
          </Input>
        )}

        {input.type === "inputNumber" && (
          <Input label={input.label} disabled={input.disabled}>
            <input
              type="number"
              id={input.key}
              value={defaultValue}
              disabled={input.disabled}
              onChange={(e) => {
                onChangeWithValidation(input, e, onChange);
              }}
            />
          </Input>
        )}

        {input.type === "label" && (
          <Input label={input.label}>{defaultValue}</Input>
        )}

        {input.type === "blockchainTypeSelect" && (
          <Input label={input.label} disabled={input.disabled}>
            <select
              name={input.key}
              id={input.key}
              onChange={(e) => onChange && onChange(e.target.value)}
              value={defaultValue}
              disabled={input.disabled}
            >
              {input.options.map((chain, i) => (
                <option value={chain.value} key={i}>
                  {chain.label ?? chain.value}
                </option>
              ))}
            </select>
          </Input>
        )}
      </div>
    </>
  );
};
