import { ReactNode } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Sprites from "../Sprites/Sprites";
import APIProvider from "../../context/api/APIProvider";
import PluginsListProvider from "../../context/plugins/PluginsList";
import Monitoring from "../Monitoring/Monitoring";
import { LayoutProps } from "../../model/Props";

export default function PageLayout({ children }: LayoutProps) {
  return (
    <>
      <APIProvider>
        <PluginsListProvider>
          <Sidebar />
          <main className="main">
            {/* <Monitoring /> */}
            {children}
          </main>
        </PluginsListProvider>
      </APIProvider>
      <Sprites />
    </>
  );
}
