import "./Sidebar.scss";
import SideBarPlugins from "../SidebarPlugins/SidebarPlugins";
import Logo from "../Logo/Logo";
import { Icon } from "@iconify/react";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import BannerAlert from "../BannerAlert/BannerAlert";
import Popin from "../Popin/Popin";
import Loader from "../Loader/Loader";
import { getKeepixInformation, postKeepixUpdateState } from "../../queries/api";
import { useQuery } from "@tanstack/react-query";
import { navigationData } from "../../data/navigation";
import { KeepixActionType } from "../../model/Keepix";
import Btn from "../Btn/Btn";
import { isElectron } from "../../lib/utils";

type SidebarListProps = {
  listActions: any[];
}

const SidebarList = ({ listActions }: SidebarListProps) => {
  return <div className="Sidebar-list">
    {listActions.map((action, key) => (
      <NavLink
        to={action.link}
        target={action.target}
        className={({ isActive }) =>
          `Sidebar-link ${isActive ? "active" : ""}`
        }
        key={key}
        title={action.name}
      >
        <Icon icon={action.icon} />
        <span>{action.name}</span>
      </NavLink>
    ))}
  </div>
}

export default function Sidebar() {
  const [isUpdatePopinOpen, setUpdatePopinOpen] = useState(false);
  const [updateIsRunning, setUpdateIsRunning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUpdateSuccessful, setUpdateSuccessful] = useState(false);
  const [error, setError] = useState('');
  const [retryCheckCounter, setRetryCheckCounter] = useState(0);

  const qKeepixInformation = useQuery({
    queryKey: ["keepixInformation"],
    queryFn: () => getKeepixInformation(),
    staleTime: 0,
    refetchOnWindowFocus: true,
    refetchInterval: () => {
      if (updateIsRunning) { return 2000; }
      return (1000 * 60) * 60;
    },
    refetchIntervalInBackground: true
  });

  const handleUpdate = async () => {
    setLoading(true);
    const result = await postKeepixUpdateState(qKeepixInformation.data?.latestVersion ?? "");

    if (result.success === false) {
      setLoading(false);
      setError(result.description);
    } else {
      setError('');
      setUpdateIsRunning(true);
    }
  };

  useEffect(() => {
    const timerId = setInterval(async () => {
      if (isUpdateSuccessful || !updateIsRunning) {
        return ;
      }
      if (retryCheckCounter > 20) {
        setError('The update is not possible at the moment, please try again later.');
        setUpdateIsRunning(false);
        setUpdateSuccessful(false);
        setLoading(false);
        setRetryCheckCounter(0);
        return ;
      }
      if (qKeepixInformation.data !== undefined && qKeepixInformation.data?.version === qKeepixInformation.data?.latestVersion) {
        setUpdateIsRunning(false);
        setUpdateSuccessful(true);
        setLoading(false);
        setRetryCheckCounter(0);
        clearInterval(timerId);
        return ;
      }
      setRetryCheckCounter(retryCheckCounter + 1);
    }, 5000);
    return () => clearInterval(timerId);
  });

  return (
    <div>
      <aside className="Sidebar-sidebar">
        <Link to={"/"} className="Sidebar-sidebarLogo">
          <Logo />
          {qKeepixInformation.data && (
            <span style={{ color: (qKeepixInformation.data.latestVersion !== qKeepixInformation.data.version) ? 'orange' : undefined }}>v{qKeepixInformation.data.version}</span>
          )}
        </Link>
        {qKeepixInformation.data && qKeepixInformation.data.latestVersion && qKeepixInformation.data.latestVersion !== qKeepixInformation.data.version && (
          <div className="Sidebar-update" style={{ marginTop: '10px' }}>
            <Btn fullWidth={true} onClick={() => { setUpdatePopinOpen(true) }} status="success">
              New version available (v{qKeepixInformation.data.latestVersion})
            </Btn>
          </div>
        )}
        <div className="Sidebar-sidebarContent">
          <SideBarPlugins />
        </div>
        <nav style={{margin: 10}}  className="Sidebar-nav">
          <div className="Sidebar-list">
            {navigationData.actions
            .filter(x => x.name != 'Terminal' || (['linux', 'osx'].includes(qKeepixInformation.data?.platform as string) && !isElectron()))
            .map((action, key) => (
              <NavLink
                to={action.link}
                target={action.target}
                className={({ isActive }) =>
                  `Sidebar-link ${isActive ? "active" : ""}`
                }
                key={key}
              >
                <Icon icon={action.icon} />
                <span>{action.name}</span>
              </NavLink>
            ))}
          </div>
        </nav>

        <nav style={{margin: 10}} className="Sidebar-nav">
          <div className="Sidebar-list">
            {navigationData.secondayActions.map((action, key) => (
              <NavLink
                to={action.link}
                target={action.target}
                className={({ isActive }) =>
                  `Sidebar-link ${isActive ? "active" : ""}`
                }
                key={key}
              >
                <Icon icon={action.icon} />
                <span>{action.name}</span>
              </NavLink>
            ))}
          </div>
        </nav>

        {isUpdatePopinOpen && (
          <>
            <Popin
              title="Keepix update"
              close={() => setUpdatePopinOpen(false)}
            >
              {(loading || updateIsRunning) && (
                <Loader />
              )}
              {error && (
                <BannerAlert status="danger">{error}</BannerAlert>
              )}
              {isUpdateSuccessful && (
                <BannerAlert status="success">Installation successful ! Please Reload the window...</BannerAlert>
              )}
              {qKeepixInformation.data && !isUpdateSuccessful && !updateIsRunning && !loading && (
                <Btn onClick={() => { handleUpdate() }} >Update To v{qKeepixInformation?.data?.latestVersion}</Btn>
              )}
            </Popin>
          </>
        )}
      </aside>
    </div>)
}
