import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import PluginPage from "./pages/PluginPage";
import PluginStorePage from "./pages/PluginStorePage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SettingsPage from "./pages/SettingsPage";
import TerminalPage from "./pages/TerminalPage";
import WalletsPage from "./pages/WalletsPage";

export default function App() {
  const queryClient = new QueryClient();

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<WalletsPage />} />
          <Route path="/wallets" element={<WalletsPage />} />
          <Route path="/terminal" element={<TerminalPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/add" element={<PluginStorePage />} />
          <Route path="/plugin/:id" element={<PluginPage />} />
        </Routes>
      </QueryClientProvider>
    </div>
  );
}
